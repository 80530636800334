// import React from 'react';
// import AppsList from './app-page/applist';
import { useEffect, useState } from 'react';
import './App.css';
import LogIn from './login/login-page';

function App() {
  console.log('iiiiiiiiiiiii');
  const path = window.location.search
    const urlParams = new URLSearchParams(path)
    const moduleNameX = urlParams.get('moduleName')
    const [moduleName,setModuleName] = useState<string>('')
    useEffect(() => {
      if(moduleNameX){
        setModuleName(moduleNameX)
      }
    },[moduleNameX])
  
  return (
    <div>
      <LogIn modulename={moduleName}/>
    </div>
  );
}

export default App;
