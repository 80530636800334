const AppURLList = {
    'sale_url' :'http://159.65.150.208/kmk_sales_app',
     'procurement_url':'http://159.65.150.208/kmk_procurement_app',
    'accounts_url' :'http://167.71.224.105/kmk_hrms',
    'assets_url' :'https://kmkassets.schemaxtech.in',
    'hrms_url' :'http://167.71.224.105/kmk_hrms',
    'events_url' :'http://159.65.150.208/kmk_sales_app',
    'whatsapp_url':'http://206.189.138.212/bi/',
    'vms_url':'http://159.65.150.208/kmk_vrs_app/',
    'kr_url':'http://159.65.150.208/kmk_kr_app/',

    
}
export default AppURLList