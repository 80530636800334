const url='http://167.71.234.87:8002/ems';
export const connection = {
    'DEPLOY_URL': url,
    // 'userid':JSON.parse(localStorage.getItem('userid')),
    // 'role':JSON.parse(localStorage.getItem('role'))
    APP_IAM_SERVER_URL: "https://ums-backend.shahiapps.in",
    APP_IAM_CLIENT_ID: "225ba80a-3678-4a37-891e-4a55079df157",
}

export default connection;
