import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from 'react-router-dom';
import { ConfigProvider, theme } from 'antd';
import enUS from 'antd/es/calendar/locale/en_US';
import { Locale } from 'antd/es/locale';
import { IAMClientProvider } from './common/iam-client-react';
import connection from './common/connection';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
console.log('pppppppppppp');

const authServerUrl = connection.APP_IAM_SERVER_URL;
const clientId = connection.APP_IAM_CLIENT_ID;

// const authServerUrl = "https://ums-backend.shahiapps.in";
// const clientId = "225ba80a-3678-4a37-891e-4a55079df157";
console.log(authServerUrl,'urllllllllllll');
console.log(clientId,'clientIdddddddddddddddddddddddddddd');

root.render(
  // <RecoilRoot>
      <HashRouter>
          <IAMClientProvider authServerUrl={authServerUrl} clientId={clientId}>
              <ConfigProvider
              locale={(enUS as unknown) as Locale}  
                   theme={{ 
                      algorithm: theme.compactAlgorithm,
                      token: {
                          colorPrimary: '#29397d'
                      }
                  }}
              >
                  <App />
              </ConfigProvider>
          </IAMClientProvider>
       </HashRouter>
  // </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
