
import React from 'react';
import { Form, Input, Button, notification, Row, Col, Carousel, Card, Divider } from 'antd';
import supporticon from "../login/login-componet-images/Support-icon.png";
import videoiconnew from "../login/login-componet-images/Videoiconnew.png";
import downloadicon from "../login/login-componet-images/Downlodeicon.png";
import WhatsappIcon from "../login/login-componet-images/Whatsappicon.png";
import SchemaxLogoWhite1 from "../login/login-componet-images/X Logo 2.png";
import slideTwo from "../login/login-componet-images/slideTwo.png"
import slideFour from "../login/login-componet-images/slideFour.jpg"
import slideFive from "../login/login-componet-images/slideFive.jpg"
import slideSix from "../login/login-componet-images/slideSix.jpg"
import slideSeven from "../login/login-componet-images/slideSeven.png"
import logoTwo from "../login/login-componet-images/logoTwo.jpg"

// import 'antd/dist/antd.css';
import './login.css'; // Custom styles
import logo from '../login/login-componet-images/logo.jpeg'
import AppURLList from '../common/appurl-list';
import { loginUser, useIAMClientState } from '../common/iam-client-react';
import { LoginUserDto } from '../common/iam-client-react/user-models';
import { Header } from 'antd/es/layout/layout';

export const LogIn =  (props:{modulename:string}) => {
//   const [login,setLogin] = useState<boolean>(false);
  const { IAMClientAuthContext, dispatch } = useIAMClientState();
//   const onFinish = (values: any) => {
//     //auth check and redirect to corresponding app if validated
//     window.location.href = AppURLList.sale_url
//   };

const onLogin = async (values: { username: any; password: any; }) => {
    try {
      const req = new LoginUserDto(
        values.username,
        values.password,
        IAMClientAuthContext.authServerUrl
      );
  
      let response = await loginUser(dispatch, req);
     
      if (!response.user) {
        return false;
      } else {
        let myObject = { 
            username: values.username, 
            userId: response.user.userId,
            appId:33,
            unitId:response.user.unitId,
            unit:response.user.unit,
            moduleName:props.modulename.toLowerCase()
          }; 
          console.log(myObject,"myObject     ^^^^^^^^^^^^^^^^^^^^")
          let encodedObject = encodeURIComponent(JSON.stringify(myObject)); 
  
        let redirectUrl = '';
        if (props.modulename === 'Sales') {
          redirectUrl = `${AppURLList.sale_url}?object=${encodedObject}`;
        } else if (props.modulename === 'Inventory') {
          redirectUrl = `${AppURLList.procurement_url}?object=${encodedObject}`;
        }else if(props.modulename==='Accounts'){
            redirectUrl=AppURLList.accounts_url;
        }else if(props.modulename==='Assets'){
            redirectUrl=AppURLList.assets_url;
        }else if(props.modulename==='HRMS'){
            redirectUrl=AppURLList.hrms_url;
        }else if(props.modulename==='Events'){
            redirectUrl=AppURLList.sale_url;
         } 
        else if(props.modulename==='RMS'){
            redirectUrl=`${AppURLList.procurement_url}?object=${encodedObject}`;
            // need uncomment
        } 
        // else if(props.modulename==='RMS'){
        //     redirectUrl=`${AppURLList.procurement_url}?moduleName=${props.modulename.toLowerCase()}`;
        //     //..need comment
        // } 
         else if(props.modulename==='KnowRepository'){
            redirectUrl=AppURLList.kr_url;
        }else if (props.modulename === 'Procurement') {
            redirectUrl = `${AppURLList.procurement_url}?object=${encodedObject}`;
          }  
  
        window.location.href = redirectUrl;
        return true;
      }
    } catch (error: any) {
      notification.config({ maxCount: 3, duration: 3, placement: "top" });
      notification.destroy();
      notification.error({
        message: "Error",
        description: error.message,
      });
      return false;
    }
  };
  

  const ProductsCards = [
        
    {
        title: 'Inspection',
        image: slideTwo,
        description: ' To thoroughly inspect incoming raw materials for quality and compliance with specifications to prevent defects and ensure consistency in product quality. A: 4-point inspection: Assessing four critical aspects (fabric appearance, hand-feel, weight, and width. B: Shade inspection: Checking for color consistency and accuracy. C: Shrinkage inspection: Measuring fabric shrinkage after washing or treatment. D: GSM Inspection: It involves measuring the weight of fabric per unit area, typically in grams per square meter'

    },
    {
        title: 'Inspection',
        image: slideFour,
        description: ' To thoroughly inspect incoming raw materials for quality and compliance with specifications to prevent defects and ensure consistency in product quality. A: 4-point inspection: Assessing four critical aspects (fabric appearance, hand-feel, weight, and width. B: Shade inspection: Checking for color consistency and accuracy. C: Shrinkage inspection: Measuring fabric shrinkage after washing or treatment. D: GSM Inspection: It involves measuring the weight of fabric per unit area, typically in grams per square meter'

    },
    {
        title: 'Inspection',
        image: slideFive,
        description: ' To thoroughly inspect incoming raw materials for quality and compliance with specifications to prevent defects and ensure consistency in product quality. A: 4-point inspection: Assessing four critical aspects (fabric appearance, hand-feel, weight, and width. B: Shade inspection: Checking for color consistency and accuracy. C: Shrinkage inspection: Measuring fabric shrinkage after washing or treatment. D: GSM Inspection: It involves measuring the weight of fabric per unit area, typically in grams per square meter'

    },
    {
        title: 'Inspection',
        image: slideSix,
        description: ' To thoroughly inspect incoming raw materials for quality and compliance with specifications to prevent defects and ensure consistency in product quality. A: 4-point inspection: Assessing four critical aspects (fabric appearance, hand-feel, weight, and width. B: Shade inspection: Checking for color consistency and accuracy. C: Shrinkage inspection: Measuring fabric shrinkage after washing or treatment. D: GSM Inspection: It involves measuring the weight of fabric per unit area, typically in grams per square meter'

    },
    {
        title: 'Inspection',
        image: slideSeven,
        description: ' To thoroughly inspect incoming raw materials for quality and compliance with specifications to prevent defects and ensure consistency in product quality. A: 4-point inspection: Assessing four critical aspects (fabric appearance, hand-feel, weight, and width. B: Shade inspection: Checking for color consistency and accuracy. C: Shrinkage inspection: Measuring fabric shrinkage after washing or treatment. D: GSM Inspection: It involves measuring the weight of fabric per unit area, typically in grams per square meter'

    },
]

const contentStyle: React.CSSProperties = {
    height: '86vh',
    color: '#fff',
    textAlign: 'center',
};

    // const handleSubmit = async () => {
    // setLogin(true)
    // }

    

    return (
    // <div className="login-container">
    //                 <Form
    //                   name="normal_login"
    //     className="login-form"
    //     initialValues={{ remember: true }}
    //     onFinish={onLogin}
    //                 >
    //     {/* <h4 className="login-title">Login</h4> */}
    //     <img style = {{paddingLeft:'21%'}} src={logo} alt={logo}/>
    //                     <Form.Item
    //                       name="username"
    //       rules={[{ required: true, message: 'Please input your Username!' }]}
    //                     >
    //       <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
    //                     </Form.Item>
    //                     <Form.Item
    //                       name="password"
    //       rules={[{ required: true, message: 'Please input your Password!' }]}
    //                     >
    //       <Input
    //         prefix={<LockOutlined className="site-form-item-icon" />}
    //         type="password"
    //                         placeholder="Password"
    //                       />
    //                     </Form.Item>

    //     <Form.Item>
    //       <Button type="primary" htmlType="submit" className="login-form-button">
    //         Log in
    //                       </Button>
    //     </Form.Item>
    //                 </Form>
    //               </div>
    <div style={{ height: '100vh', overflowY: 'hidden' }} >
        <Header style={{ padding: '10px', border: '1px solid gray',height:"72px" }} className="header">
                <Row >
                    <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ color: 'white', marginTop: '-3px' }}>
                        {/* <h1 style={{ color: 'white', marginTop: '-15px' }}>Schemax</h1> */}
                        <img src={logo} alt="/" style={{ height: "57px",borderRadius:"10px" }} />
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ display: 'flex', justifyContent: 'center', marginTop: '-5px' }}>
                        <img src={SchemaxLogoWhite1} alt="/" style={{ height: "57px" }} />
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ display: 'flex', justifyContent: 'flex-end'}}>
                        <a href="tel:+919014375798" >
                        <img src={supporticon} alt='/' style={{ fontSize: '50px', height: "30px", marginLeft: '20px', cursor: 'pointer',marginTop: '8px'}} /></a>
                        <img src={videoiconnew} alt="/" style={{ fontSize: '24px', height: "25px", marginLeft: '15px', cursor: 'pointer', marginTop: '11px' }} />
                        <img
                            // onClick={downloadFile}
                            src={downloadicon}
                            alt="/" style={{ fontSize: '24px', height: "30px", marginLeft: '15px', cursor: 'pointer', marginTop: '11px' }}
                        />
                        <a aria-label="Chat on WhatsApp" target="_blank" rel="noreferrer" href="https://wa.me/919014375798?text=Hi%20there%21%20I%27m%20reaching%20out%20for%20some%20help.%20Can%20you%20assist%20me%20%3F">
                            <img alt="Chat on WhatsApp" src={WhatsappIcon} style={{ color: "white", fontSize: '24px', height: "30px", marginLeft: '12px', cursor: 'pointer',marginTop: '9px' }} className="whatsappicon" />
                        </a>
                    </Col>
                </Row>
            </Header>
            <Row>
            <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ span: 16, order: 1 }} lg={{ span: 18, order: 1 }}>
                    <Card style={{ background: 'white', height: '100%', }}
                        bodyStyle={{ padding: "0px" }}
                    >
                        <Carousel
                            arrows={true}
                            className="login-c"
                            autoplay
                            slidesToShow={1} speed={500}>
                            {ProductsCards.map((card, key) => {
                                return <div >
                                    <p style={contentStyle}>
                                        <div key={key}
                                        >
                                            <div className="flip-container">
                                                <div className="flipper">
                                                    <div className="front">
                                                        <div>
                                                            <img style={{
                                                                height: '89vh',
                                                                width: '100%',
                                                                top: '0', marginTop: "-15px"
                                                            }} className="mb-2" src={card.image} alt='/'/>
                                                        </div>
                                                    </div>
                                                    <div className="back">
                                                        <Col className="justify-center">
                                                            <h3 className="">
                                                                {card.title}
                                                            </h3>
                                                            <p className="description" style={{ color: "black" }}>
                                                                {card.description}
                                                            </p>
                                                        </Col>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            })}
                        </Carousel>

                    </Card>
            </Col>
            <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 8, order: 2 }} lg={{ span: 6, order: 2 }}
                    style={{ display: 'flex', justifyContent: 'center' }}>
                    <Card style={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', fontFamily: 'cursive',
                       
                        boxShadow: 'none',
                        borderRadius: 0,
                        border: 'none'
                    }} className="loginBackground">

                            <Row style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }} className="circle-container">
                                <img src={logoTwo} alt="/" style={{
                                  
                                }} />
                            </Row>
                            <Card style={{
                            
                            border: 'none', width: "280px", height: "280px",borderTopRightRadius: '15%'
                        }}>
                                <div className="logo">

                                <Form name="normal_login"
                                    className="container"
                                    initialValues={{
                                        remember: true,
                                    }} onFinish={onLogin}>
                                    <Col span={24}>
                                        <Form.Item
                                                name='username'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your Username!',
                                                    },
                                                   
                                                ]}>
                                                <Input placeholder="Username"
                                                    className="custom-placeholder"
                                                    style={{
                                                        background: '#fff', border: 'none', width: "230px", height: "40px", color: 'black', boxShadow: '0 0 10px #530a0a'
                                                    }}
                                                    autoComplete="new-username"

                                                />
                                            </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name='password' rules={[{ required: true, message: 'Please input your password!' }]}>
                                                <Input.Password
                                                   
                                                    className="custom-password-input"
                                                    placeholder="Password"
                                                    
                                                    style={{
                                                        background: '#fff', border: 'none', width: "230px", height: "40px", color: 'black', boxShadow: '0 0 10px #530a0a'
                                                    }}

                                                    autoComplete="new-password"
                                                />
                                        </Form.Item>
                                    </Col>
                                    
                                        <Col span={24}>
                                            <Button
                                                type="primary" htmlType="submit"
                                                style={{
                                                    textSizeAdjust: '50',
                                                    backgroundColor: '#530a0a',
                                                    width: "230px", color: '#FFFFFF',
                                                    height: "40px", fontSize: '18px',
                                                    border: "black",
                                                    fontFamily: "calibri",
                                                    fontWeight: 'bold',
                                                    boxShadow: '#530a0a -4px 15px 40px -8px',
                                                    letterSpacing: '1px',
                                                }}>Login</Button>
                                        </Col>
                                </Form>
                            </div>
                            </Card>
                        
                        <div style={{
                            position: 'absolute',
                            bottom: '0px',
                            right: '2px',
                            color: 'white',
                            fontSize: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            <Divider style={{ color: '#ffff',marginBottom: "-1px"}}>Powered by @SchemaxTech</Divider>
                            <span style={{
                                alignSelf: 'flex-start', fontSize: '15px',
                                fontWeight: 700,
                                marginLeft: '0rem'
                            }}></span>
                        </div>


                    </Card>
                </Col>
            </Row>
    </div>
  );
  };

export default LogIn;